/* eslint-disable unicorn/prefer-dom-node-append */
/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable unicorn/prevent-abbreviations */
import { useEffect } from 'react';
import { IS_PRODUCTION } from 'utils';

declare global {
  interface Window {
    hj: (...args: unknown[]) => void;
    _hjSettings: {
      hjid: string;
      hjsv: string;
    };
  }
}

const HOTJAR_ID = '5268978';
const HOTJAR_VERSION = '6';

export const HotjarProvider = ({ children }: { children: JSX.Element }) => {
  useEffect(() => {
    if (!IS_PRODUCTION) return;

    // Initialize Hotjar
    (function (h: Window, o: Document, t: string, j: string) {
      h.hj =
        h.hj ||
        function () {
          // @ts-ignore - TS doesn't know that this is a function
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_VERSION };
      const a = o.getElementsByTagName('head')[0];
      const r = o.createElement('script');
      r.async = true;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  return children;
};
